// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Bio from '../components/bio';
import Link from '../components/link';

interface Tool {
    name: string;
    url: string;
    github: string;
    desc?: string;
}

interface Data {
    site: {
        siteMetadata: {
            tools: Array<Tool>;
            title: string;
        };
    };
}

const Tools = ({ data }: PageProps<Data>) => {
    const tools = data.site.siteMetadata.tools;

    return (
        <Layout>
            <SEO title="My Tools" />
            <Bio title="Blogs"></Bio>
            <div>
                {tools.map(({ name, github, url, desc }) => {
                    return (
                        <div className="mb-8" key={name}>
                            <h5>
                                <Link href={url}>{name}</Link>
                            </h5>
                            <p>{desc}</p>
                        </div>
                    );
                })}
            </div>
        </Layout>
    );
};

export default Tools;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                tools {
                    name
                    url
                    github
                    desc
                }
            }
        }
    }
`;
